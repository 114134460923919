import "./App.scss";
import { useAppSelector } from "./store/store";
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import theme from "asset/themes";
import NavigationScroll from "container/layout/NavigationScroll";
import { SetAxiosDefaults } from "helpers/interceptor";
import { KeycloackContext } from "pages/authentication/keycloak/useKeyCloak";
import { useContext, useEffect } from "react";
import RoutePath from "routes";
import { clearStore } from "utils/redux.utils";

function App() {
  const customization = useAppSelector((state: any) => state.customization);
  const { keycloakValue } = useContext(KeycloackContext);
  SetAxiosDefaults();

  useEffect(() => {
    keycloakValue.onAuthLogout = () => {
      console.log("User logged out");
      clearStore();
      localStorage.clear();
    };

    return () => {
      // Clean up event listeners on component unmount
      keycloakValue.onAuthLogout = null;
    };
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme(customization)}>
          <CssBaseline>
            <NavigationScroll>
              <RoutePath />
            </NavigationScroll>
          </CssBaseline>
        </ThemeProvider>
      </LocalizationProvider>
    </StyledEngineProvider>
  );
}

export default App;

import { Suspense, lazy } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { useAppSelector } from "store/store";
import Loader from "ui-component/Loader";
import { IncidentReportingRole } from "utils/constant.utils";

/* incident reporting roles */
const { ADMIN } = IncidentReportingRole;

/* Guest role authentication */
const GuestRoute = () => {
  return <Outlet />;
};

/* Protected role authentication */
const ProtectedUserRoute = ({ roles }: { roles: string[] }) => {
  const loggedUser = useAppSelector((state) => state.user.user);
  const location = useLocation();
  return roles?.some((role: string) => loggedUser?.role?.includes(role)) ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

/* guest routes */
const PageNotFound = lazy(() => import("pages/PageNotFound"));
const ComingSoon = lazy(() => import("layout/hoc/coming-soon"));

const Login = lazy(() => import("pages/authentication/Login"));

/* layout component */
const Layout = lazy(() => import("../layout/layout/MainLayout"));

/* profile user component */
const ProfileView = lazy(
  () => import("../layout/pages/profile-details/profile-basic-details")
);
const ProfileEdit = lazy(
  () => import("../layout/pages/profile-details/profile-basic-edit")
);

/* admin routes */
const IncidentDashboard = lazy(() => import("pages/admin/IncidentDashboard"));
const IncidentList = lazy(() => import("pages/admin/Incidents/IncidentList"));
const IncidentView = lazy(() => import("pages/admin/Incidents/IncidentView"));
const IncidentReports = lazy(
  () => import("pages/admin/Incidents/IncidentReports")
);
const ResourcesList = lazy(() => import("pages/admin/ResourcesList"));

const RoutePath = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/">
          <Route path="*" element={<PageNotFound />} />
          <Route path="not-found" element={<PageNotFound />} />
          <Route path="/" element={<Navigate to="login" replace />} />
          {/* Guest routes */}
          <Route element={<GuestRoute />}>
            <Route path="/login" element={<Login />} />
          </Route>
          <Route element={<ProtectedUserRoute roles={[ADMIN]} />}>
            <Route element={<Layout />}>
              <Route path={`/profile_details`} element={<ProfileView />} />
              <Route path={`/profile_edit`} element={<ProfileEdit />} />

              <Route path={`/coming_soon`} element={<ComingSoon />} />
              <Route path={`/dashboard/coming_soon`} element={<ComingSoon />} />
              <Route path={`/services/coming_soon`} element={<ComingSoon />} />
              <Route
                path={`/site_admin/coming_soon`}
                element={<ComingSoon />}
              />
              <Route
                path={`/member_portal/coming_soon`}
                element={<ComingSoon />}
              />
              <Route
                path={`/my_business/coming_soon`}
                element={<ComingSoon />}
              />
              <Route path={`/reports/coming_soon`} element={<ComingSoon />} />
              <Route path={`/help/coming_soon`} element={<ComingSoon />} />
              <Route path={`/coming_soon_1`} element={<ComingSoon />} />
              <Route path={`/coming_soon_2`} element={<ComingSoon />} />

              {/* Services routes */}
              <Route
                path={`/services/${ADMIN}/dashboard`}
                element={<IncidentDashboard />}
              />
              <Route
                path={`/services/${ADMIN}/incidents_list`}
                element={<IncidentList />}
              />

              <Route
                path={`/services/${ADMIN}/incident_view/:id`}
                element={<IncidentView />}
              />

              {/* Reports routes */}
              <Route
                path={`/reports/${ADMIN}/incidents`}
                element={<IncidentReports />}
              />

              {/* Site Admin routes */}
              <Route
                path={`/site_admin/${ADMIN}/resources_list`}
                element={<ResourcesList />}
              />
            </Route>
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};

export default RoutePath;

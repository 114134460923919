const defaultRole ={
  admin: 'profile',
  superadmin: 'admin',
  profile: 'profile',
  adminuser: 'employee',
  agent: 'agent',
  driver: 'driver',
  fleet_operator: 'fleet_operator',
  vehicleowner: 'vehicle_owner',
  service_provider: 'service_provider',
}

export default defaultRole;
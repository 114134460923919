import axios from "axios";
import { keycloakValue } from "pages/authentication/keycloak/useKeyCloak";
import { clearStore } from "utils/redux.utils";

const mainAxios = process.env.REACT_APP_IR_END_URL;
const ssoAxios = process.env.REACT_APP_SSO_URL;
const dcAxios = process.env.REACT_APP_DC_END_URL;
axios.defaults.baseURL = mainAxios;
export const IsBaseURL = (setCommonCode = true, URLName: any = "ir") => {
  axios.defaults.baseURL =
    URLName === "ir" ? mainAxios : URLName === "dc" ? dcAxios : ssoAxios;
};

export const SetAxiosDefaults = () => {
  axios.interceptors.request.use(onRequestFulfilled, onRequestRejected);
  axios.interceptors.response.use(onResponseFulfilled, onResponseRejected);
};

const onRequestFulfilled = async (config: any) => {
  let token = sessionStorage.getItem("at-ir-token");
  if (token && token !== "undefined") {
    if (keycloakValue?.updateToken) {
      const refreshToken = await keycloakValue
        .updateToken(300)
        .then((ses: any) => ses)
        .catch((err: any) => console.log("err", err));
      if (refreshToken)
        sessionStorage.setItem("at-ir-token", keycloakValue?.token || "");
    }
    token = sessionStorage.getItem("at-ir-token");
    config.headers.Authorization = `Bearer ${token}`;
  }
  return Promise.resolve(config);
};

const onRequestRejected = (error: any) => {
  console.log("error", error);
  return Promise.reject(error);
};

const onResponseFulfilled = (config: any) => {
  return Promise.resolve(config);
};

const onResponseRejected = (error: any) => {
  console.log("error", error);
  if (error?.response?.status === 401 || error?.response?.status === 403) {
    sessionStorage.clear();
    clearStore();
    window.location.href = "/login";
  }
  return Promise.reject(error);
};

export const resetSession = () => {
  sessionStorage.removeItem("at-ir-auth");
};


import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";

export function parsePhoneNumber(phoneNumber: any) {
  try {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const parsedNumber = phoneNumberUtil.parse(phoneNumber, 'ZZ');

    if (!phoneNumberUtil.isValidNumber(parsedNumber)) {
      return null; // Invalid phone number
    }

    const countryCode = parsedNumber.getCountryCode();
    const nationalNumber: any = parsedNumber.getNationalNumber();

    return {
      countryCode: `+${countryCode}`,
      mobileNumber: nationalNumber.toString(),
    };
  } catch (err) {
    return {
      countryCode: null,
      mobileNumber: null,
    };
  }
}

export const formatZipcode = (value: any, isCheckNumber = true) => {
  try {
    let finalValue = "";

    var val = isCheckNumber ? value.replace(/\D/g, "") : value;
    var newVal = "";

    if (val.length > 5) {
      newVal += val.substr(0, 5) + "-";
      val = val.substr(5);
    }
    newVal += val;
    finalValue = newVal.substring(0, 10);

    return finalValue;
  }
  catch {
    return value
  }
}

export function parsePhoneNumberformat(countryCode: any, mobileNumber: any) {
  try {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const phoneNumber = `+${countryCode}${mobileNumber}`;
    const parsedNumber = phoneNumberUtil.parse(phoneNumber, 'ZZ');

    if (!phoneNumberUtil.isValidNumber(parsedNumber)) {
      return null; // Invalid phone number
    }

    const formattedNumber = phoneNumberUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);

    return formattedNumber;
  } catch (error) {
    return null; // Invalid phone number
  }
}

export const formatSsn = (value: any, isCheckNumber = true) => {
  try {
    let finalValue = "";

    var val = isCheckNumber ? value.replace(/\D/g, "") : value;
    var newVal = "";
    if (val.length > 4) {
      finalValue = val;
    }
    if (val.length > 3 && val.length < 6) {
      newVal += val.substr(0, 3) + "-";
      val = val.substr(3);
    }
    if (val.length > 5) {
      newVal += val.substr(0, 3) + "-";
      newVal += val.substr(3, 2) + "-";
      val = val.substr(5);
    }
    newVal += val;
    finalValue = newVal.substring(0, 11);

    return finalValue;
  }
  catch {
    return value
  }
};

export const handleKeyNumberPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
  const enteredKey = event.key;
  if (!/^[0-9]$/.test(enteredKey)) {
    event.preventDefault();
  }
};

export const handleKeyDecimalPress = (event: any) => {
  const inputValue = event.key;
  // Allow digits, a decimal point (if not already present), and specific control keys
  const isValidKey =
    /^\d$/.test(inputValue) ||
    (inputValue === '.' && event.target.value.indexOf('.') === -1) ||
    event.key === 'Backspace' ||
    event.key === 'Delete' ||
    event.key === 'ArrowLeft' ||
    event.key === 'ArrowRight' ||
    event.key === 'Home' ||
    event.key === 'End';

  if (!isValidKey) {
    event.preventDefault();
  }
}
import { IncidentReportingRole } from "utils/constant.utils";

const admin = {
  id: `dashboard`,
  title: `Dashboard`,
  type: `group`,
  appName: "Back to Services",
  backServices: `${process.env.REACT_APP_DC_REDIRECTION_URL}login?url=/profile/services_menu`,
  children: [
    {
      id: `default`,
      title: `Dashboard`,
      type: `item`,
      url: `/dashboard/coming_soon`,
      icon: `gridView`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/dashboard/coming_soon`],
    },
    {
      id: `services`,
      title: `Services`,
      type: `collapse`,
      url: `/services/${IncidentReportingRole.ADMIN}/incidents_list`,
      icon: `Dns`,
      breadcrumbs: false,
      activeItem: [
        `/services/${IncidentReportingRole.ADMIN}/dashboard`,
        `/services/${IncidentReportingRole.ADMIN}/incidents_list`,
        `/services/${IncidentReportingRole.ADMIN}/incident_view`,
      ],
      children: [
        {
          id: `services-dashboard`,
          title: `Dashboard`,
          type: `item`,
          url: `/services/${IncidentReportingRole.ADMIN}/dashboard`,
          icon: `SpeedMeter`,
          parentId: "services",
          breadcrumbs: false,
          href: false,
          activeItem: [`/services/${IncidentReportingRole.ADMIN}/dashboard`],
        },
        {
          id: `incidents`,
          title: `Incidents`,
          type: `item`,
          url: `/services/${IncidentReportingRole.ADMIN}/incidents_list`,
          icon: `CarCrash`,
          parentId: "services",
          breadcrumbs: false,
          href: false,
          activeItem: [
            `/services/${IncidentReportingRole.ADMIN}/incidents_list`,
            `/services/${IncidentReportingRole.ADMIN}/incident_view`,
          ],
        },
      ],
    },
    {
      id: `member_portal`,
      title: `Member Portal`,
      type: `item`,
      url: `/member_portal/coming_soon`,
      icon: `userPerson`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/member_portal/coming_soon`],
    },
    {
      id: `my_business`,
      title: `My Business`,
      type: `item`,
      url: `/my_business/coming_soon`,
      icon: `AccountBalance`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/my_business/coming_soon`],
    },
    {
      id: `reports`,
      title: `Reports`,
      type: `collapse`,
      url: `${process.env.REACT_APP_FHT_REDIRECTION_URL}login?url=/admin/Reports`,
      icon: `userReport`,
      breadcrumbs: false,
      href: true,
      activeItem: [`/reports/${IncidentReportingRole.ADMIN}/incidents`, `${process.env.REACT_APP_FHT_REDIRECTION_URL}login?url=/admin/Reports`,],
      children: [
        {
          id: `reports-incidents`,
          title: `Incidents`,
          type: `item`,
          url: `/reports/${IncidentReportingRole.ADMIN}/incidents`,
          icon: `ReportFile`,
          parentId: "reports",
          breadcrumbs: false,
          href: false,
          activeItem: [`/reports/${IncidentReportingRole.ADMIN}/incidents`],
        },
        {
          id: "legal_services_reports",
          title: "Legal Services Reports",
          type: "item",
          url: `${process.env.REACT_APP_FHT_REDIRECTION_URL}login?url=/admin/Reports`,
          icon: "userReport",
          parentId: "reports",
          breadcrumbs: false,
          href: true,
        },
      ],
    },
    {
      id: `siteadministration`,
      title: `Site Admin`,
      type: `collapse`,
      url: `/site_admin/${IncidentReportingRole.ADMIN}/resources_list`,
      icon: `ManageAccounts`,
      breadcrumbs: false,
      href: false,
      activeItem: [
        `/site_admin/${IncidentReportingRole.ADMIN}/resources_list`,
        `/coming_soon_2`,
      ],
      children: [
        {
          id: `adminusers`,
          title: `Admin Users`,
          type: `item`,
          url: `${process.env.REACT_APP_DC_REDIRECTION_URL}auth_verification_token?url=/admin/siteadministration/adminusers`,
          icon: `PeopleAlt`,
          breadcrumbs: false,
          parentId: "siteadministration",
          href: true,
        },
        {
          id: `documenttemplate`,
          title: `Document Template`,
          type: `item`,
          url: `/coming_soon_2`,
          icon: `description`,
          breadcrumbs: false,
          parentId: "siteadministration",
          href: false,
          activeItem: [`/coming_soon_2`],
        },
        {
          id: "location",
          title: "Legal Services Master",
          type: "item",
          url: `${process.env.REACT_APP_FHT_REDIRECTION_URL}login?url=/admin/master/service`,
          icon: "location",
          href: true,
          parentId: "master",
        },
        {
          id: `airdrops`,
          title: `Airdrops`,
          type: `item`,
          url: `${process.env.REACT_APP_DW_REDIRECTION_URL}login?url=/admin/airdrops_list`,
          icon: `Workspace`,
          parentId: "siteadministration",
          href: true,
        },
        {
          id: `coupns`,
          title: `Coupons`,
          type: `item`,
          url: `${process.env.REACT_APP_DW_REDIRECTION_URL}login?url=/admin/coupons_list`,
          icon: `LocalOffer`,
          parentId: "siteadministration",
          href: true,
        },
        {
          id: `tokens`,
          title: `Tokens`,
          type: `item`,
          url: `${process.env.REACT_APP_DW_REDIRECTION_URL}login?url=/admin/tokens_list`,
          icon: `TokenIcon`,
          parentId: "siteadministration",
          href: true,
        },
        {
          id: `coupon_categories`,
          title: `Coupon Categories`,
          type: `item`,
          url: `${process.env.REACT_APP_DW_REDIRECTION_URL}login?url=/admin/coupon_categories`,
          icon: `CategoryIcon`,
          parentId: "siteadministration",
          href: true,
        },
        {
          id: `resources`,
          title: `Resources`,
          type: `item`,
          url: `/site_admin/${IncidentReportingRole.ADMIN}/resources_list`,
          icon: `Settings`,
          parentId: "siteadministration",
          breadcrumbs: false,
          href: false,
          activeItem: [
            `/site_admin/${IncidentReportingRole.ADMIN}/resources_list`,
          ],
        },
      ],
    },
  ],
};

export default admin;

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "interface/common.interface";
import { IMenuItems, IPopupItems, IUserSlice } from "interface/redux.interface";
import { IStateList } from "layout/interface/common.interface";

const initialState: IUserSlice = {
  token: null,
  user: {} as IUser,
  menuItems: [] as IMenuItems[],
  subMenuItems: [] as IMenuItems[],
  popupItems: [] as IPopupItems[],
  notiCount: 0,
  dwNotiCount: 0,
  stateLists: [] as IStateList[],
};

export const counterSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setTokenRedux(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    setUserRedux(state, action: PayloadAction<IUser>) {
      state.user = action.payload;
    },
    setMenuItemsRedux(state, action: PayloadAction<IMenuItems[]>) {
      state.menuItems = action.payload;
    },
    setSubMenuItemsRedux(state, action: PayloadAction<IMenuItems[]>) {
      state.subMenuItems = action.payload;
    },
    setPopupItemsRedux(state, action: PayloadAction<IPopupItems[]>) {
      state.popupItems = action.payload;
    },
    setNotiCount(state, action: PayloadAction<number>) {
      state.notiCount = action.payload;
    },
    setDWNotiCount(state, action: PayloadAction<any>) {
      state.dwNotiCount = action.payload;
    },
    setStateListsRedux(state, action: PayloadAction<IStateList[]>) {
      state.stateLists = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTokenRedux,
  setUserRedux,
  setMenuItemsRedux,
  setSubMenuItemsRedux,
  setPopupItemsRedux,
  setNotiCount,
  setStateListsRedux,
  setDWNotiCount,
} = counterSlice.actions;

export default counterSlice.reducer;

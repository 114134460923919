import { useState, useEffect, createContext } from 'react'
import Keycloak from 'keycloak-js';
import { clearStore } from 'utils/redux.utils';
import { useNavigate } from 'react-router-dom';
import { commonService } from 'forms/helpers/common.service';
import { Failed } from "layout/helper/toast.helper";
import { errorMessage } from 'layout/helper/function.helper';
import axios from "axios";


const KeycloackContext = createContext();

const keycloakValue = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID,
  realm: process.env.REACT_APP_KEYCLOAK_REALM
});

const headers = {
  "x-api-key": process.env.REACT_APP_SSO_API_KEY,
  "app-name": process.env.REACT_APP_SSO_APP_NAME,
};

const KeycloackContextProvider = (props) => {
  const [keycloak, setKeycloakValue] = useState(null)
  const [authenticated, setAuthenticated] = useState(false)
  const navigate = useNavigate();
  const setKeyclock = () => {
    keycloakValue.init({
      // onLoad: 'login-required',
    }).then(authenticated => {
      setKeycloakValue(keycloakValue)
      setAuthenticated(authenticated)
    }).catch(error => {
      console.error('Error during Keycloak initialization:', error);
    });
  }
  useEffect(() => {
    setKeyclock()
  }, []);

  const logout = async () => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_SSO_URL}/v2/sso/auth/logout`,
      data: {},
      headers: headers
    }).then(() => {
      setKeycloakValue(null)
      setAuthenticated(false)
      keycloak.logout({
        redirectUri:`${process.env.REACT_APP_EXCELSIOR_HOME_PAGE_URL}`,
      })
      clearStore();
      localStorage.clear();
      sessionStorage.clear();
      // navigate("/login");
    }).catch((error) => {
      console.log(error);
      Failed(
        errorMessage(error, "Something went wrong, please try again later")
      );
    })
  }

  return (
    <KeycloackContext.Provider
      value={{
        keycloak,
        authenticated,
        logout,
        keycloakValue
      }}
    >
      {props['children']}
    </KeycloackContext.Provider>
  )
};

export { KeycloackContextProvider, KeycloackContext, keycloakValue }
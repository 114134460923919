import { IPopupItems } from "interface/redux.interface";

export const adminPopupItem: IPopupItems[] = [
  {
    path: `/profile_details`,
    title: "Profile",
    icon: "profile",
  },
  {
    path: `/resetpassword`,
    title: "Reset Password",
    icon: "settings",
  },
  {
    title: "Logout",
    icon: "logout",
    isLogout: true,
  },
];
